<template>
  <Modal ref="parent" @close="$emit('close')">
    <div class="relative py-5 text-center">
      <p class="mb-5">{{ message }}</p>
      <p class="mb-2">
        <a :href="url"
          ><img class="h-10 mx-auto" src="/imgs/payme-button.png" alt="pay with payme"
        /></a>
      </p>

      <p class="text-xs text-gray-400 tracking-wide">
        在 {{ timers }} 秒後自動重新定向 <br />如沒有請點擊上面按鈕重新定向
      </p>
    </div>
  </Modal>
</template>

<script>

import Loading from "@/components/icons/Loading.vue";
import { XIcon } from "@vue-hero-icons/outline";
export default {
  components: {
    XIcon,
    Loading,
  },
  props: {
    url: String,
    message: String,
    timers: {
      type: Number,
      default: 3,
    },
  },
  methods: {
    close() {
      this.$refs.parent.close();
    },
    redirectToPayMe() {
      window.location.href = this.url;
    },

    countdown() {

      const interval = setInterval(() => {
        this.timers--;
        if (this.timers == 0) {
          clearInterval(interval);
          this.redirectToPayMe();
        }
      }, 1000);
    },
  },
  mounted() {
    this.countdown();
  },
};
</script>

<style lang="css" scoped></style>

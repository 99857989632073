import {UAParser} from "ua-parser-js";
export default ({store}) => {
  const parser = new UAParser(navigator.userAgent);
  const {name} = parser.getBrowser();
  if (/Safari/.test(name)) {
    store.commit("app/SET_SAFARI", true);
  } else {
    store.commit("app/SET_SAFARI", false);
  }
};

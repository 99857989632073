export const state = () => ({
  items: [],
  amount: 0,
  offer: 0,
  count: 0,
  required_apple_id: false,
  required_qq_number: false,
  payment: "cash",
});

export const getters = {
  items(state) {
    return state.items;
  },

  amount(state) {
    return state.amount;
  },

  offer(state) {
    return state.offer;
  },

  count(state) {
    return state.count;
  },

  required_apple_id(state) {
    return state.required_apple_id;
  },

  required_qq_number(state) {
    return state.required_qq_number;
  },

  hasItems(state) {
    return state.count > 0;
  },
};

export const mutations = {
  SET_CART(state, data) {
    state.items = data.items;
    state.amount = data.amount;
    state.offer = data.offer;
    (state.count = data.quantity),
      (state.required_apple_id = data.required_apple_id);
    state.required_qq_number = data.required_qq_number;
    state.payment = data.payment;
  },
};

export const actions = {
  fetch({commit}) {
    return this.$axios.$get("/api/cart").then(({data}) => {
      commit("SET_CART", data);
    });
  },
  clear({getters, dispatch}) {
    if (!getters.hasItems) {
      return;
    }
    if (!confirm("確定要清空購物車？")) {
      return;
    }
    this.$axios.delete("/api/cart").then(() => {
      dispatch("fetch");
    });
  },
  empty({commit}) {
    commit("SET_CART", {
      items: [],
      amount: 0,
      count: 0,
      payment: "cash",
    });
  },
};

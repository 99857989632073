<template>
  <div class="text-sm tracking-wider overflow-y-scroll">
    <switchLang @switch="setLang" :language="language" />
    <div v-if="language == 'zh'" class="max-h-40 ">
      <p class="mb-2">
        無論是誰要求您向他發送兌換卡密，他絕對是騙子!千萬不要相信他!
      </p>
      <p class="mb-2">
        騙子通常會在網絡上跟您相識，例如透過諸如微信、Facebook、Instagram
        等的平台。他們會
        發佈廣告，聲稱可獲取高額的利潤，引誘受害者去與他們進行交易，接着便會以一些諸如交
        易費、註冊費或手續費等的名義要求您先付一定金額的款項，可能是數百至數萬元不等，然
        後引導受害者到我們的平台購買兌換卡密給他們，通常會要求您將兌換卡密複製或截圖含有
        卡密的畫面給他們，一旦兌換卡密暴露給他們，該兌換卡密就會立即被使用並失去其價值。
      </p>
      <p class="mb-2">
        在一些情況下，犯罪者會非法地獲得您的個人資料甚至色情照片，他們會威脅您給他們買我
        們的兌換卡密。首先，請務必保護您的個人資料和自身，但是，如這樣的不幸事件真的發生
        了的話，您唯一該做的事是向警方求助，犯罪者不會因為您按照他們說的做就刪除您的個人
        資料，他們日後會繼續以此威脅您，然而，這將是無底洞，所以，請務必去向警方求助，而
        不是徒勞無功地向他們付錢。
      </p>
      <p class="mb-2">
        犯罪者會在網上向您發文字訊息或甚至打電話給您，從而獲取您的信任或威脅您，而最終目
        的是要獲取您的兌換卡密，千萬不要向他們發送兌換卡密或是任何帶有兌換卡密的截圖。
      </p>
      <p class="mb-2">詐騙手法層出不窮，如懷疑受騙，請即聯絡警方求助。</p>
      <p class="mb-2">
        澳門 24 小時防詐騙查詢熱線: <a class="text-primary" href="tel:88007777"
          >8800 7777</a
        >
      </p>
    </div>
    <div v-if="language == 'en'" class="max-h-40">
      <p class="mb-2">
        No matter who tells you to send him the redeeming card code, he is
        ABSOLUTELY a FRAUDSTER! NEVER ever trust him!
      </p>
      <p class="mb-2">
        Fraudsters usually get in touch with you on internet, such as WeChat,
        Facebook, Instagram, etc. They will post Ads of incredibly huge amount
        profits to induce you to do transaction with them and tell you to make a
        payment in the name of transaction fee, registration fee or handling
        fee, which is roughly from several hundred to several thousand, by
        buying our redeeming card code and then to copy or to make a screenshot
        of the redeeming card code to them. Once redeeming card code getting
        exposed to them, the redeeming card code itself will immediately lose
        its value.
      </p>
      <p class="mb-2">
        Do NOT send any card codes or any screenshot pictures with codes to
        fraudsters.
      </p>
      <p class="mb-2">
        In some cases, like criminals illegally getting your private information
        or even pornography, they will threaten you to buy them our redeeming
        card code. First of all, please do protect your private information and
        yourself. However, if such an unfortunate case did happen, the only
        thing you should do would be to get help from police. Criminals will NOT
        delete your private information even if you really do what they tell
        you. They will keep extorting from you and it is a bottomless pit, so
        please do go get help from police instead of paying them in vain.
      </p>
      <p class="mb-2">
        Criminals will text you on the internet or even make a phone call to you
        in order to acquire your trust or to threaten you for the final purpose
        of your redeeming card code. Do NOT send any redeeming card codes or any
        screenshot pictures with codes to criminals.
      </p>
      <p class="mb-2">
        Ways to carry out fraudulence are getting complicated. If in doubt,
        immediately call local police institution for further help.
      </p>
      <p class="mb-2">
        Macao Anti-Fraud Hotline:
        <a class="text-primary" href="tel:88007777">8800 7777</a>
      </p>
    </div>
  </div>
</template>

<script>
import switchLang from "@/components/vendor/lang";
export default {
  components: {
    switchLang,
  },
  data() {
    return {
      language: "zh",
    };
  },
  methods: {
    setLang(lang) {
      this.language = lang;
    },
  },
};
</script>

<style lang="scss" scoped></style>

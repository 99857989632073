<template>
  <div class="bg-white max-w-screen-sm mx-auto w-full">
    <div class="grid grid-cols-3 border-t leading-none">
      <nuxt-link
        v-for="(nav, index) in navs"
        :key="index"
        :to="localePath({ name: nav.name })"
        class="text-center py-2 tracking-wider block"
        :class="isActive(nav.names)"
      >
        <component :is="nav.icon" class="mx-auto mb-1" />
        <p class="text-xxs">{{ nav.text }}</p>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import { startsWith } from "lodash";
import { HomeIcon, UserIcon, ClipboardIcon } from "@vue-hero-icons/outline";
export default {
  components: {
    HomeIcon,
    UserIcon,
    ClipboardIcon,
  },
  data() {
    return {
      navs: [
        {
          name: "index",
          text: "商城",
          names: ["order", "index"],
          icon: "HomeIcon",
        },
        {
          name: "takings",
          text: "訂單",
          names: ["takings"],
          icon: "ClipboardIcon",
        },
        { name: "me", text: "我的", names: ["me"], icon: "UserIcon" },
      ],
    };
  },
  methods: {
    isActive(arr) {
      const name = this.$route.name;
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        if (startsWith(name, element)) return "text-primary font-bold";
      }

      return " text-gray-400";
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <canvas class="mx-auto" :class="[size]" />
</template>

<script>
import qrcode from "@/modules/payment-qrcode-dialog/qrcode.js";
import { drawPayCode } from "@/modules/payment-qrcode-dialog/paycode.js";

export default {
  props: {
    text: {
      type: String,
    },
    size: {
      type: Number,
      default: 300,
    },
    light: {
      type: String,
      default: "#ffffff",
    },
    dark: {
      type: String,
      default: "#db0011",
    },
    img: {
      type: String,
    },
  },
  methods: {
    suggestTypeNumber() {
      const length = this.text.length;
      if (length <= 32) {
        return 3;
      } else if (length <= 46) {
        return 4;
      } else if (length <= 60) {
        return 5;
      } else if (length <= 74) {
        return 6;
      } else if (length <= 86) {
        return 7;
      } else if (length <= 108) {
        return 8;
      } else if (length <= 130) {
        return 9;
      } else if (length <= 151) {
        return 10;
      } else if (length <= 177) {
        return 11;
      } else if (length <= 203) {
        return 12;
      } else if (length <= 241) {
        return 13;
      } else if (length <= 258) {
        return 14;
      } else if (length <= 292) {
        return 15;
      } else {
        return 40;
      }
    },
    createQrcode(text, typeNumber, errorCorrectionLevel, mode, mb) {
      qrcode.stringToBytes = qrcode.stringToBytesFuncs[mb];
      if (typeNumber == 0) {
        typeNumber = this.suggestTypeNumber(text);
      }

      var qr = qrcode(typeNumber || 4, errorCorrectionLevel || "M");
      qr.addData(text, mode);
      qr.make();

      return qr;
    },
  },
  async mounted() {
    
    const canvas = this.$el;
    const ctx = canvas.getContext("2d");
    const size = this.size;
    // const qr = qrcode.create(this.text, {
    //   errorCorrectionLevel: "Q",
    //   color: {
    //     light: this.light,
    //     dark: this.dark,
    //   },
    //   margin: 1,
    //   version: this.suggestTypeNumber(),
    //   size,
    // });

    canvas.width = size;
    canvas.height = size;
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // console.log(qr);
    // const qr = qrcode.toCanvas(
    //   canvas,
    //   this.text,
    //   {
    //     errorCorrectionLevel: "Q",
    //     color: {
    //       light: this.light,
    //       dark: this.dark,
    //     },
    //     margin: 1,
    //     version: this.suggestTypeNumber(),
    //     width,
    //   },
    //   function (error) {
    //     if (error) console.error(error);
    //   }
    // );
    // const ctx = canvas.getContext("2d");
    // draw logo in center
    let img = new Image();
    img.src = this.img;
    // img.onload = () => {
    //   const cwidth = size * 0.2;
    //   const center = (size - cwidth) / 2;
    //   ctx.drawImage(img, center, center, cwidth, cwidth);
    // };
    // // draw logo in bottom right
    // const img2 = new Image();
    // img2.src = this.img;
    // img2.onload = () => {
    //   const cwidth = width * 0.12;
    //   ctx.drawImage(img2, width - cwidth, width - cwidth, cwidth, cwidth);
    // };
    // return canvas.toDataURL("image/png");
    const qr = this.createQrcode(this.text, 0, "Q", "Byte", "UTF-8");
    drawPayCode(qr, canvas, 7, img, false);
  },
};
</script>

<style lang="scss" scoped></style>

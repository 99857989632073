export default function ({$axios, store, redirect}) {
  $axios.setHeader("X-Requested-With", "XMLHttpRequest");

  if (process.server) {
    $axios.setHeader("accept-encoding", "gzip");
    $axios.setHeader("cf-connecting-ip", "");
  }

  $axios.onError((error) => {
    const {method, url, baseURL} = error.response.config;
    // store.dispatch('load/unsetLoading', `${url.replace(baseURL, '')}-${method}`)
    if (error.response.status === 422) {
      store.dispatch("validation/setErrors", error.response.data.errors);
    }

    if (error.response.status === 401) {
      store.dispatch("me/logout");
      return false;
    }

    if (error.response.status == 403 || error.response.status == 404) {
      redirect({name: "404", params: {...error.response}});
      return false;
    }

    return Promise.reject(error);
  });

  $axios.onRequest(({method, url, headers}) => {
    // store.dispatch('load/setLoading', `${url}-${method}`)
    // headers['Content-Type'] = 'application/json';
    // headers['Access-Control-Allow-Origin'] = "*";
    store.dispatch("validation/clearErrors");

    // get the current locale
    const locale = store.$i18n.locale;
    headers["Accept-Locale"] = locale;
  });

  $axios.onResponse(({config}) => {
    // const {method, url, baseURL} = config
    // store.dispatch('load/unsetLoading', `${url.replace(baseURL, '')}-${method}`)
  });
}

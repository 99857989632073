<template>
  <div class="text-xs leading-normal" @click="copy">
    <span class="__code">{{code}}</span>
    <span class="bg-primary text-white rounded text-xxs px-2 py-1" >複製</span>
  </div>
</template>

<script>
export default {
  props: {
    code: String
  },
  methods: {
    copy(e) {
      const el = this.$el.querySelector(".__code");
      this.copyStringToClipboard(el);
      this.$loader.success(`複製成功 \n ${el.textContent} `);
    },
    copyStringToClipboard(el) {
      const selection = window.getSelection();
      const range = document.createRange();

      range.selectNodeContents(el);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
    }
  }
};
</script>

<template>
  <Modal
    view="side-up"
    ref="parent"
    @close="$emit('close')"
    :outsideClickable="true"
  >
    <div
      class="flex flex-col h-full overflow-hidden max-w-screen-sm w-full mx-auto"
    >
      <div class="relative w-full">
        <!-- 關閉按鈕 -->
        <button class="p-4" :class="[color]" @click="close">
          <XIcon />
        </button>
      </div>

      <div class="flex-grow">
        <div class="p-10">
          <ul class="text-center font-medium" :class="[color]">
            <li v-for="item in menu" :key="item.title" class="mb-4">
              <button
                type="button"
                class="py-3 flex items-center justify-center space-x-4 border-2 rounded-lg w-full"
                :class="[color, borderColor]"
                @click="item.action"
                v-if="!item.hide"
              >
                <component :is="item.icon" /><span>{{ item.title }}</span>
              </button>
            </li>
          </ul>
        </div>
        <div class="h-40"></div>
      </div>
    </div>
  </Modal>
</template>

<script>
import {XIcon, LinkIcon, PaperAirplaneIcon} from "@vue-hero-icons/outline";
import clipboard from "copy-to-clipboard";
export default {
  components: {
    XIcon,
    LinkIcon,
    PaperAirplaneIcon,
  },
  props: {
    title: String,
    url: String,
    text: String,
    color: {
      type: String,
      default: "text-primary",
    },
    borderColor: {
      type: String,
      default: "border-primary",
    },
  },
  data() {
    return {
      sharedata: {
        title: this.title,
        text: this.text,
        url: this.url,
      },
      menu: [
        {
          title: "分享到",
          icon: "PaperAirplaneIcon",
          action: this.shareTo,
          hide: !this.sharable(),
        },
        {
          title: "複製連結",
          icon: "LinkIcon",
          action: this.copyUrl,
        },
      ],
    };
  },
  methods: {
    async shareTo() {
      try {
        if (this.inapp) {
          await window.flutter_inappwebview.callHandler(
            "share",
            this.sharedata
          );
        } else {
          await navigator.share(this.sharedata);
        }
        this.close();
      } catch (e) {
        console.error(e);
      }
    },
    async copyUrl() {
      await clipboard(this.sharedata.url);
      this.$loader.success(`已複製連結，貼上至你的朋友的聊天室`);
      this.close();
    },
    close() {
      this.$refs.parent.close();
    },
    sharable() {
      return navigator.canShare;
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div :style="{height: `${height}rem`}"></div>
</template>

<script>
export default {
  props: {
    height: {
      type: [Number, String],
      default: 4
    }
  }
};
</script>

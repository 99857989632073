<template>
  <component :is="tag">
    <slot></slot>
    <div v-if="error">
      <span class="text-xxs text-red-500" style="padding-left: 10px;">{{ error[0] }}</span>
    </div>
  </component>
</template>


<script>
export default {
  props: {
    error: [Object, Array, String],
    tag: {
      type: String,
      default: "div"
    }
  }
};
</script>

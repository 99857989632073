<template>
  <div class="relative h-app">
    <div class="flex flex-col h-full max-w-screen-sm mx-auto w-full">
      <TitleBarCard :title="title" @close="close" />

      <div class="flex-grow bg-white overflow-auto p-5 scrollbar-hide">
        <img class="w-full h-auto" :src="image" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TitleBarCard from "@/components/card/TitleBarCard.vue";
export default {
  components: {TitleBarCard},

  methods: {
    close() {
      this.$parent.close();
    },
  },
  computed: {
    ...mapGetters({
      type: "taking/type",
    }),

    title() {
      if (this.type == "transaction_boc") return "中銀BOC支付的交易認證教學";
      if (this.type == "transaction_mpay") return "MPay支付的交易認證教學";
      return "發送短訊教學";
    },

    image() {
      if (this.type == "transaction_boc") return "/imgs/legend_boc.png";
      if (this.type == "transaction_mpay") return "/imgs/legend_mpay.png";
      return "/imgs/sms.jpg";
    },
  },
};
</script>

<style lang="scss" scoped></style>

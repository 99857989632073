<template>
  <div>
    <label class="label mb-2">{{variation.name}}</label>
    <div class="grid grid-cols-4 gap-2">
      <div
        class="text-center"
        v-for="option in variation.options"
        :key="option.name"
        @click="checked(option)"
      >
        <div
          class="border text-sm rounded-sm tracking-wider leading-none whitespace-pre-wrap px-1 pt-1 pb-2"
          :class="isActive(option)? 'bg-primary border-primary text-white': 'border-dove-gray text-dove-gray'"
        >{{option.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { indexOf, find } from "lodash";

export default {
  props: {
    variation: Object,
    selected: {
      type: Object,
      default() {
        return {
          options: []
        };
      }
    }
  },
  methods: {
    checked(option) {
      this.$emit("checked", {
        max: this.max,
        min: this.min,
        option: option.id,
        variation: this.variation.id
      });
    },
    isActive(option) {
      return indexOf(this.selected.options, option.id) > -1;
    }
  },
  created() {
    const option = find(this.variation.options, { default: true });
    if (option) {
      this.checked(option);
    }
  },
  computed: {
    max() {
      return this.variation.max;
    },
    min() {
      return this.variation.min;
    }
  }
};
</script>

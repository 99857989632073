<template>
  <v-field :error="errors[`options.${option.name}`]" class="mb-4">
    <template v-if="option.type == 'input'">
      <input
        :id="option.name"
        class="field"
        type="text"
        @input="onInput"
        :placeholder="option.placeholder"
      />
    </template>
    <template v-if="option.type == 'select'">
      <select
      class="field"
        :id="option.name"
        :placeholder="option.placeholder"
        @change="onInput"
      >
        <option value="">請選{{ option.label_zh }}</option>
        <option
          v-for="_option in option.options"
          :key="_option.value"
          :value="_option.value"
        >
          {{ _option.label }}
        </option>
      </select>
    </template>
  </v-field>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      require: true,
    },
  },
  methods: {
    onInput(e) {
      this.$emit("input", e.target.value);
    }
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <CartPanel @toggle="toggle" />
    <van-popup
      position="bottom"
      v-model="show"
      round
      class="z-[999999999999] max-w-screen-sm mx-auto inset-x-0 overflow-hidden"
    >
      <CartList />
    </van-popup>
  </div>
</template>

<script>
import CartList from "@/components/cart/CartList";

import CartPanel from "@/components/cart/CartPanel.vue";

export default {
  components: { CartList, CartPanel },
  data() {
    return {
      show: false,
      show_cart: false,
    };
  },
  methods: {
    toggle() {
      if (this.atCheckout || this.atConfirm) return false;
      this.show = !this.show;
    },
  },
};
</script>

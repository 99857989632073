<template>
  <div class="flex flex-col items-stretch h-app overflow-hidden ">
    <AppBar
      class="flex-none z-20"
      :title="title"
      :backable="backable"
      :back="GoBack"
      :product="product"
    >
      <template #action>
        <slot name="appbar-action" />
      </template>
    </AppBar>
    <div
      class="flex flex-col flex-grow overflow-y-scroll overflow-x-hidden scrollbar-hide"
    >
      <div class="flex-grow" :class="orderPage ? 'h-full' : ''">
        <slot />
      </div>
      <AppFooter v-if="footerShow" class="flex-none" />
    </div>
  </div>
</template>

<script>
import AppBar from "./AppBar.vue";
import AppFooter from "./AppFooter.vue";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    backable: {
      type: Boolean,
      default: true,
    },
    footerShow: {
      type: Boolean,
      default: true,
    },
    orderPage: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
    },
  },
  components: { AppBar, AppFooter },
};
</script>

<style lang="scss" scoped></style>

<template>
  <CartCard>
    <div
      class="flex items-center w-full flex-wrap h-full cursor-pointer p-1"
      @click="$emit('toggle')"
    >
      <div class="flex-none flex items-center justify-center">
        <ShoppingBagIcon class="ml-5" />
        <div class="bg-white w-6 h-6 rounded-full flex items-center justify-center">
          <span class="text-xs leading-none font-bold text-primary">{{ count }}</span>
        </div>
      </div>

      <div class="flex-grow pr-5">
        <p class="text-right text-xxs">
          {{ currency
          }}<span class="text-base font-bold">{{ amount | number_format }}</span>
        </p>
      </div>

      <button
        type="button"
        class="flex-none h-full px-10 bg-white text-primary rounded-r font-bold"
        @click.stop="checkout"
      >
        {{ btnText }}
      </button>
    </div>
  </CartCard>
</template>

<script>
import CartCard from "@/components/cart/CartCard.vue";
import { ShoppingBagIcon } from "@vue-hero-icons/outline";
import { mapGetters } from "vuex";

export default {
  components: { CartCard, ShoppingBagIcon },
  methods: {
    checkout() {
      if (!this.hasItems) return;

      if (this.inCheckoutPage) return $nuxt.$emit("submit-checkout");

      this.$router.push(this.localePath({ name: "order-checkout" }));
    },
  },
  computed: {
    ...mapGetters({
      amount: "cart/amount",
      offer: "cart/offer",
      count: "cart/count",
      hasItems: "cart/hasItems",
      items: "cart/items",
    }),
    atCheckout() {
      return this.$route.name.match("order-checkout");
    },
    btnText() {
      return this.atCheckout ? "確定" : "提交";
    },
    inCheckoutPage() {
      return this.$route.name.match("order-checkout");
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <Modal ref="parent" @close="$emit('close')">
    <div class="relative">
      <div class="absolute top-4 right-4">
        <button type="button" @click="close">
          <XIcon />
        </button>
      </div>
      <div v-if="logo">
        <img :src="logo" alt="" class="w-64" />
      </div>
      <div class="h-10"></div>
      <p class="text-center font-medium mb-2 text-xl">{{ title }}</p>
      <QRcode :text="text" :img="img" />
      <p class="text-center tracking-wide mt-4 text-gray-400 text-sm mb-8">
        {{ subject }}
      </p>

      <div class="border-t py-4">
        <p class="mb-4">{{ tips }}</p>
        <ul class="grid grid-cols-3 text-sm gap-x-2" v-if="steps">
          <li class="guideline" v-for="(step, index) in steps" :key="index">
            <span class="step">{{ index+1 }}</span><span class="flex-grow">{{ step }}</span>
          </li>
        </ul>
      </div>
    </div>
  </Modal>
</template>

<script>

import { XIcon } from "@vue-hero-icons/outline";
import QRcode from "./QRcode";
export default {
  components: {
    QRcode,
    XIcon,
  },
  props: {
    title: String,
    subject: String,
    tips: String,
    steps: Array,
    logo: String,
    text: String,
    img: String
  },
  methods: {
    paied() {},
    close() {
      this.$refs.parent.close();
    },
  },
};
</script>

<style lang="css" scoped>
.step {
  @apply bg-gray-600 rounded-full text-white font-bold w-6 h-6 flex justify-center flex-none items-center mr-2;
}

.guideline {
  @apply flex items-start justify-start;
}
</style>

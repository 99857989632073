<template>
  <div class="fixed inset-x-0 z-10 max-w-screen-sm m-auto px-2 bottom-20 w-full">
    <div class="flex flex-wrap text-white h-14 overflow-hidden">
      <div
        class="flex-shrink flex flex-col items-center justify-center w-16 rounded-r-lg mr-1"
      ></div>

      <div class="flex-grow bg-main rounded ml-1 flex items-center flex-wrap">
        <p class="pl-4 text-xs font-medium" v-if="!hasItems" v-html="info.welcome" />

        <slot v-if="hasItems" />
      </div>
    </div>
  </div>
</template>

<script>
import { ChatIcon } from "@vue-hero-icons/outline";
import { mapGetters } from "vuex";

export default {
  components: {
    ChatIcon,
  },
  computed: {
    ...mapGetters({
      hasItems: "cart/hasItems",
      info: "app/info",
    }),
  },
};
</script>

<style scoped>
.h-73px {
  height: 4.5625rem;
}
</style>

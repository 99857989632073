import {isNull} from "lodash";

export const state = () => ({
  records: [],
  meta: {},
  legend: false,
  type: "",
});

export const getters = {
  records(state) {
    return {
      data: state.records,
      meta: state.meta,
    };
  },
  legend(state) {
    return state.legend;
  },
  type(state) {
    return state.type;
  },
};

export const mutations = {
  SET_DATA(state, data) {
    state.records.push(...data.data);
    state.meta = data.meta;
  },
  RESET_DATA(state) {
    state.records = [];
    state.meta = {};
  },
  SET_LEGEND(state, {show, type}) {
    state.legend = show;
    state.type = type;
  },
};

export const actions = {
  fetch({state, commit}, page) {
    return this.$axios.$get("/api/takings", {params: {page}}).then((resp) => {
      commit("SET_DATA", resp);
    });
  },
  clear({commit}) {
    commit("RESET_DATA");
  },
  cancelLegend({commit}) {
    commit("SET_LEGEND", {
      show: false,
      type: "",
    });
  },
};

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2525d038 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _375f581b = () => interopDefault(import('../pages/bindAccount.vue' /* webpackChunkName: "pages/bindAccount" */))
const _1826f4f6 = () => interopDefault(import('../pages/consent.vue' /* webpackChunkName: "pages/consent" */))
const _04e5ac7d = () => interopDefault(import('../pages/me.vue' /* webpackChunkName: "pages/me" */))
const _61ea9988 = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _7000ca04 = () => interopDefault(import('../pages/procedure.vue' /* webpackChunkName: "pages/procedure" */))
const _6bb6c8bb = () => interopDefault(import('../pages/setting.vue' /* webpackChunkName: "pages/setting" */))
const _3c0a935d = () => interopDefault(import('../pages/takings/index.vue' /* webpackChunkName: "pages/takings/index" */))
const _1701e146 = () => interopDefault(import('../pages/wx.vue' /* webpackChunkName: "pages/wx" */))
const _1ee5d0c3 = () => interopDefault(import('../pages/auth/binding.vue' /* webpackChunkName: "pages/auth/binding" */))
const _52ff9556 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _d7453e1a = () => interopDefault(import('../pages/auth/help.vue' /* webpackChunkName: "pages/auth/help" */))
const _46205127 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _6914e255 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _7ccded48 = () => interopDefault(import('../pages/oauth/authorize.vue' /* webpackChunkName: "pages/oauth/authorize" */))
const _16d45a68 = () => interopDefault(import('../pages/order/checkout.vue' /* webpackChunkName: "pages/order/checkout" */))
const _3a92ddaa = () => interopDefault(import('../pages/order/confirm.vue' /* webpackChunkName: "pages/order/confirm" */))
const _1e0c4e67 = () => interopDefault(import('../pages/teams/policies/privacy.vue' /* webpackChunkName: "pages/teams/policies/privacy" */))
const _6ebc74e9 = () => interopDefault(import('../pages/teams/policies/refund.vue' /* webpackChunkName: "pages/teams/policies/refund" */))
const _517f9314 = () => interopDefault(import('../pages/teams/policies/service.vue' /* webpackChunkName: "pages/teams/policies/service" */))
const _44ddaafd = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6bb00756 = () => interopDefault(import('../pages/order/product/_product.vue' /* webpackChunkName: "pages/order/product/_product" */))
const _ee206df6 = () => interopDefault(import('../pages/takings/_id.vue' /* webpackChunkName: "pages/takings/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _2525d038,
    name: "about___tw"
  }, {
    path: "/bindAccount",
    component: _375f581b,
    name: "bindAccount___tw"
  }, {
    path: "/consent",
    component: _1826f4f6,
    name: "consent___tw"
  }, {
    path: "/me",
    component: _04e5ac7d,
    name: "me___tw"
  }, {
    path: "/order",
    component: _61ea9988,
    name: "order___tw"
  }, {
    path: "/procedure",
    component: _7000ca04,
    name: "procedure___tw"
  }, {
    path: "/setting",
    component: _6bb6c8bb,
    name: "setting___tw"
  }, {
    path: "/takings",
    component: _3c0a935d,
    name: "takings___tw"
  }, {
    path: "/wx",
    component: _1701e146,
    name: "wx___tw"
  }, {
    path: "/auth/binding",
    component: _1ee5d0c3,
    name: "auth-binding___tw"
  }, {
    path: "/auth/forgot",
    component: _52ff9556,
    name: "auth-forgot___tw"
  }, {
    path: "/auth/help",
    component: _d7453e1a,
    name: "auth-help___tw"
  }, {
    path: "/auth/login",
    component: _46205127,
    name: "auth-login___tw"
  }, {
    path: "/auth/register",
    component: _6914e255,
    name: "auth-register___tw"
  }, {
    path: "/oauth/authorize",
    component: _7ccded48,
    name: "oauth-authorize___tw"
  }, {
    path: "/order/checkout",
    component: _16d45a68,
    name: "order-checkout___tw"
  }, {
    path: "/order/confirm",
    component: _3a92ddaa,
    name: "order-confirm___tw"
  }, {
    path: "/teams/policies/privacy",
    component: _1e0c4e67,
    name: "teams-policies-privacy___tw"
  }, {
    path: "/teams/policies/refund",
    component: _6ebc74e9,
    name: "teams-policies-refund___tw"
  }, {
    path: "/teams/policies/service",
    component: _517f9314,
    name: "teams-policies-service___tw"
  }, {
    path: "/",
    component: _44ddaafd,
    name: "index___tw"
  }, {
    path: "/order/product/:product?",
    component: _6bb00756,
    name: "order-product-product___tw"
  }, {
    path: "/takings/:id",
    component: _ee206df6,
    name: "takings-id___tw"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

<template>
  <div class="flex flex-col">
    <div class="flex-grow">
      <nuxt />
    </div>

    <div class="grid grid-cols-3 border-t border-gray-light leading-none">
      <nuxt-link
        class="text-center py-5 tracking-wider font-medium"
        :class="isActive(nav.names)"
        tag="div"
        v-for="(nav, index) in navs"
        :key="index"
        :to="{name: nav.name}"
        >{{ nav.text }}</nuxt-link
      >
    </div>
  </div>
</template>

<script>
import {debounce, startsWith} from "lodash";

export default {
  components: {},
  data() {
    return {
      navs: [
        {
          name: "index",
          text: `${process.env.APP_NAME}`,
          names: ["order", "index"],
        },
        {name: "takings", text: "我的訂單", names: ["takings"]},
        {name: "me", text: "我的設置", names: ["me"]},
      ],
    };
  },
  methods: {
    isActive(arr) {
      const name = this.$route.name;
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        if (startsWith(name, element)) return "bg-primary text-white";
      }

      return "";
    },

    resize: debounce(function () {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, 200),
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize);
  },
};
</script>

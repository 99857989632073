<template>
  <div class="flex flex-col items-center my-4">
    <!-- 留白 40px -->
    <div class="h-10"></div>

    <div class="mb-2">
      <img :src="imgs.logo_long" :alt="info.name" class="w-28 object-contain" />
    </div>
    <div class="text-sm font-bold mb-4">{{ info.name }}</div>
    <div class="flex justify-center mb-4 space-x-4">
      <p v-for="item of socialList" :key="item.name">
        <a v-if="item.name != 'WeChat'" :href="item.src" target="_blank">
          <img :src="item.icon" :alt="item.name" class="w-8 h-8" />
        </a>
        <nuxt-link v-else :to="item.src">
          <img :src="item.icon" :alt="item.name" class="w-8 h-8" />
        </nuxt-link>
      </p>
    </div>
    <div class="text-sm flex flex-wrap space-x-4 justify-center mb-4">
      <nuxt-link
        v-for="(item, index) of restList"
        :key="index"
        :to="item.to"
        class="px-2 text-xs mb-2 flex-none"
        >{{ item.tag }}</nuxt-link
      >
    </div>
    <div class="text-sm">
      <a :href="`mailto:${info.email}`" class="font-bold text-primary">{{
        info.email
      }}</a>
    </div>
    <div class="text-xs mb-4 text-center">
      <p>©{{ year }} {{ info.name }}</p>
      <p class="mt-4">
        Developed by
        <a href="https://iclover.net" target="_black" class="font-bold text-primary"
          >CLOVER COMPUTER LTD.</a
        >
      </p>
    </div>

    <!-- 留白 -->
    <div class="h-40"></div>
  </div>
</template>

<script>
import facebookIcon from "@/static/Facebook_icon.svg";
import instagramIcon from "@/static/Instagram_icon.svg";
import wechatIcon from "@/static/Wechat_icon.svg";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },

  computed: {
    ...mapGetters({
      info: "app/info",
      imgs: "app/imgs",
    }),
    restList() {
      return [
        { tag: "購買流程", to: "/procedure" },
        { tag: "退換政策", to: "/teams/policies/refund" },
        {
          tag: `${this.info.name}網站用戶註冊協議`,
          to: "/teams/policies/service",
        },
        { tag: "隱私政策", to: "/teams/policies/privacy" },
        { tag: `關於${this.info.name}`, to: "/about" },
      ];
    },

    socialList() {
      return [
        {
          name: "FaceBook",
          icon: facebookIcon,
          src: this.info.facebook,
        },
        {
          name: "Instagram",
          icon: instagramIcon,
          src: this.info.instagram,
        },
        { name: "WeChat", icon: wechatIcon, src: "/about" },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>

export default function ({redirect, $auth, route, i18n}) {
  // check route name contains order
  // if (route.name.includes('order') && ) { return }

  if (!$auth.loggedIn) {
    return;
  }

  const locale = i18n.locale === i18n.defaultLocale ? "" : "/" + i18n.locale;
  // if user is registered, and route name is auth-register, redirect to order
  if (
    !$auth.user.data?.registered &&
    !(
      route.name.includes("auth-register") ||
      route.name.includes("auth-binding")
    )
  ) {
    redirect(`${locale}/auth/binding`);
  }
}

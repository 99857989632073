<template>
  <div class="flex flex-row bg-primary text-white items-center justify-between">
    <div class="flex items-center">
      <button type="button" class="pl-4 py-4" @click="back" v-if="!onlytext">
        <ChevronLeftIcon />
      </button>
      <p class="py-4 pl-4">{{ text }}</p>
    </div>

    <slot />
  </div>
</template>

<script>
import { ChevronLeftIcon } from "@vue-hero-icons/outline";

export default {
  components: {
    ChevronLeftIcon,
  },
  props: {
    text: {
      type: String,
      default: "返回",
    },
    onlytext: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    back() {
      this.$emit("back");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
export const actions = {
  async nuxtServerInit({ dispatch, getters }, { store, route, redirect, $auth, i18n, $axios }) {

    if (route.name?.includes('order-checkout')) {
      redirect("/order")
      return;
    }

    try {
      // 處理登入後的 redirect
      if (route.query.access_token) {
        // get auth.strategy
        const authStrategy = $auth.strategy.name;
        $axios.setToken(route.query.access_token, 'Bearer')
        // set token
        $auth.setToken(authStrategy, "Bearer " + route.query.access_token);
        // fetch user
        await $auth.fetchUser();
      
      }
    } catch (e) {
      console.error(e);
      // clear all auth storage
      $auth.reset();

      redirect("/auth/login")
    }

    // 處理登入後的 redirect
    var redirect = $auth.$storage.options.redirect;
    for (var key in redirect) {
      // if locale is default then redirect to /..
      const locale = i18n.locale;
      if (locale == i18n.defaultLocale) {
        redirect[key] = redirect[key];
      } else {
        redirect[key] = "/" + locale + redirect[key];
      }
    }
    
    $auth.$storage.options.redirect = redirect;
    await dispatch("app/init");
    await dispatch("app/fetchCategories");
    

    if ($auth.loggedIn) {
      await dispatch("cart/fetch")
    }

    

  },
}
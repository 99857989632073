<template>
  <div
    class="flex relative justify-center items-center text-white bg-primary flex-none h-14"
  >
    <!-- 關閉按鈕 -->
    <button class="absolute p-4 left-0 top-0" @click="$emit('close')">
      <XIcon />
    </button>

    <!-- title -->
    <div class="line-clamp-1 font-medium py-4">
      {{ title }}
    </div>

    <!-- 分享按鈕 -->
    <button class="absolute p-4 right-0 top-0" type="button" @click="$emit('share')" v-if="sharable">
      <ShareIcon />
    </button>
  </div>
</template>

<script>
import { XIcon, ShareIcon } from "@vue-hero-icons/outline";
export default {
  components: { XIcon, ShareIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    sharable: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <p class="text-sm font-bold">
    <a
      href="#"
      :class="{ 'text-primary': language == 'zh', 'text-gray-400': language != 'zh' }"
      @click.prevent="$emit('switch', 'zh')"
      >中文</a
    >
    /
    <a
      href="#"
      :class="{ 'text-primary': language == 'en', 'text-gray-400': language != 'en' }"
      @click.prevent="$emit('switch', 'en')"
      >EN</a
    >
  </p>
</template>

<script>
export default {
  props: {
    language: {
      type: String,
      default: 'zh'
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
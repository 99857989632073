import Vue from 'vue';
import App from '@/modules/payme-dialog/App.vue';

export default function (options) {
  let myapp = {
    vm: null,

    close(resolve, status) {
      document.body.removeChild(this.vm.$el);
      this.vm.$destroy();
      resolve({
        status
      });
    },

    open(data) {
      return new Promise((resolve, reject) => {
        const dialog = Vue.extend(App);

        const vm = new dialog({
          propsData: data
        }).$mount();

        document.body.appendChild(vm.$el);

        // listen to close event
        vm.$on('close', () => {
          this.close(resolve, 'close');
        });

        // listen to confirm event
        vm.$on('confirm', () => {
          this.close(resolve, 'confirm');
        });

        this.vm = vm;
      })
    }
  }

  Vue.prototype.$payme = myapp;
}
<template>
  <button type="button" class="flex items-center" @click="copy">
    {{ text }}
    <ClipboardIcon v-if="!copied" class="w-4 h-4 ml-2" />
    <ClipboardCheckIcon v-if="copied" class="w-4 h-4 ml-2"/>
  </button>
</template>

<script>
import clipboard from "copy-to-clipboard";
import { ClipboardIcon, ClipboardCheckIcon } from "@vue-hero-icons/outline";
export default {
  components: {
    ClipboardIcon,
    ClipboardCheckIcon,
  },
  props: {
    text: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    async copy() {
      if (this.copied) return;

      await clipboard(this.text);
      this.success();
    },

    success() {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
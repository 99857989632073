export const state = () => ({
  enable: false,
  item: {}
})

export const getters = {
  enable(state) {
    return state.enable
  },

  item(state) {
    return state.item
  }
}

export const mutations = {
  SET_ITEM(state, data) {
    state.item = data
  },
  SET_ENABLE(state, bool) {
    state.enable = bool
  },
}

export const actions = {
  show({ commit }, item) {
    // if (inWeiXin()) {
    //   this.$router.push(this.localePath('/wx'));
    //   return;
    // }
    commit('SET_ITEM', item)
    commit('SET_ENABLE', true)
  },
  cancel({ commit }) {
    commit('SET_ENABLE', false)
    commit('SET_ITEM', {})
  },
  setItem({commit}, item) {
    commit('SET_ITEM', item)
  }
}


function inWeiXin() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  }
  return false;
}
<template>
  <div class="flex flex-row items-center">
    <button type="button" class="btn btn-sm py-0" @click="minus" :disabled="disabled">
      <MinusIcon />
    </button>
    <span class="px-2">{{ value }}</span>
    <button type="button" class="btn btn-sm py-0" @click="add" :disabled="disabled">
      <PlusIcon />
    </button>
  </div>
</template>


<script>
import { MinusIcon, PlusIcon } from "@vue-hero-icons/outline";

export default {
  components: { MinusIcon, PlusIcon },
  props: {
    value: [String, Number],
    onlyonce: Boolean,
    min: {
      type: Number,
      default: 1,
    },
    disabled: Boolean
  },

  methods: {
    add() {
      if (this.disabled) return;
      this.$emit("input", this.value + 1);
    },
    minus() {
      if (this.disabled) return;
      if (this.value <= this.min) return;
      const qty = this.value - 1;

      this.$emit("input", qty);
    },
  },
};
</script>

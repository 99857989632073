export const state = () => ({
  form: {},
  step: 1
})

export const getters = {
  form(state) {
    return state.form
  },

  step(state) {
    return state.step
  }
}

export const mutations = {
  SET(state, data) {
    state.form = data
  },
  SET_STEP(state, step) {
    state.step = step
  },
  CLEAR(state) {
    state.form = {}
    state.step = 1
  }
}

export const actions = {
  save({ commit }, form) {
    commit('SET', form)
    commit('SET_STEP', 2)
  },
  map({ commit, state }, form) {
    commit('SET', { ...state.form, ...form })
  },
  clear({commit}) {
    commit('CLEAR')
  }
}

<template>
  <textarea class="resize-none" cols="1" rows="1" @input="typing" :value="value" />
</template>

<script>
export default {
  props: ["value"],
  methods: {
    typing(e) {
      this.$emit("input", e.target.value);
    },
  },
  mounted() {
    autosize(this.$el);
    $(this.$el).keydown(function (e) {
      const keyCode = e.keyCode || e.which;

      if (keyCode === 9) {
        e.preventDefault();

        const TAB_SIZE = 2;

        // The one-liner that does the magic
        document.execCommand("insertText", false, " ".repeat(TAB_SIZE));
      }
    });
  },
};
</script>
